import { ROUTES } from 'Consts/routes';
import { SETTINGS_LIST } from 'Pages/Settings/definitions';
import { SSID_CONFIGURATION_LIST } from 'Pages/SsidConfiguration';
import { lazy } from 'react';

// Import components that will be re-exported from their parent modules
const {
  IntroPage,
  LoginPage,
  BellLoginPage,
  MagicLinkPage,
  GlobalAuthPage,
  ResetPasswordPage,
} = {
  IntroPage: lazy(() =>
    import('Pages/Onboarding').then((module) => ({ default: module.IntroPage }))
  ),
  LoginPage: lazy(() =>
    import('Pages/Onboarding').then((module) => ({ default: module.LoginPage }))
  ),
  BellLoginPage: lazy(() =>
    import('Pages/Onboarding').then((module) => ({
      default: module.BellLoginPage,
    }))
  ),
  MagicLinkPage: lazy(() =>
    import('Pages/Onboarding').then((module) => ({
      default: module.MagicLinkPage,
    }))
  ),
  GlobalAuthPage: lazy(() =>
    import('Pages/Onboarding').then((module) => ({
      default: module.GlobalAuthPage,
    }))
  ),
  ResetPasswordPage: lazy(() =>
    import('Pages/Onboarding').then((module) => ({
      default: module.ResetPasswordPage,
    }))
  ),
};

// Import zone components
const {
  Employee: EmployeeZone,
  GuestZone,
  Secure: SecureZone,
} = {
  Employee: lazy(() =>
    import('Pages/Zones').then((module) => ({ default: module.Employee }))
  ),
  GuestZone: lazy(() =>
    import('Pages/Zones').then((module) => ({ default: module.GuestZone }))
  ),
  Secure: lazy(() =>
    import('Pages/Zones').then((module) => ({ default: module.Secure }))
  ),
};

// Direct imports
const Home = lazy(() => import('Pages/Homepage'));
const WlanCard = lazy(() => import('Pages/Homepage/Wlan view all'));
const DevicesViewAll = lazy(
  () => import('Pages/Zones/Device management view all')
);
const PopularDevicesViewAll = lazy(
  () => import('Pages/Zones/Guest zone/Popular devices view all')
);
const Shield = lazy(() => import('Pages/Shield'));
const Network = lazy(() => import('Pages/Network'));
const Settings = lazy(() => import('Pages/Settings'));
const Device = lazy(() => import('Pages/Device'));
const Employee = lazy(() => import('Pages/Employee'));
const NotFound = lazy(() => import('Pages/Not found'));
const PartnerAuthenticationCallbackPage = lazy(
  () => import('Pages/PartnerAuthenticationCallback')
);
const AppTimeInsights = lazy(() => import('Pages/AppTimeInsights'));
const SsidConfiguration = lazy(() => import('Pages/SsidConfiguration'));
const Insurance = lazy(() => import('Pages/Insurance'));
const NextIframe = lazy(() => import('Pages/Insurance/NextIframe'));

// Type definitions
type RouteConfig = {
  path: string;
  component: React.LazyExoticComponent<any>;
  protected: boolean;
  props?: Record<string, any>;
};

export const routeConfig: RouteConfig[] = [
  // Onboarding routes (unprotected)
  {
    path: ROUTES.onboarding.index,
    component: IntroPage,
    protected: false,
  },
  {
    path: ROUTES.onboarding.magicLink,
    component: MagicLinkPage,
    protected: false,
  },
  {
    path: ROUTES.onboarding.globalAuth,
    component: GlobalAuthPage,
    protected: false,
  },
  {
    path: ROUTES.onboarding.login,
    component: LoginPage,
    protected: false,
  },
  {
    path: ROUTES.onboarding.bellLogin,
    component: BellLoginPage,
    protected: false,
  },
  {
    path: ROUTES.onboarding.resetPassword,
    component: ResetPasswordPage,
    protected: false,
  },
  {
    path: ROUTES.partnerAuthenticationCallback,
    component: PartnerAuthenticationCallbackPage,
    protected: false,
  },

  // Protected routes
  {
    path: ROUTES.home.index,
    component: Home,
    protected: true,
  },
  {
    path: ROUTES.home.wlan,
    component: WlanCard,
    protected: true,
  },

  // Zones routes
  {
    path: ROUTES.zones.secure.index,
    component: SecureZone,
    protected: true,
  },
  {
    path: ROUTES.zones.secure.approved,
    component: DevicesViewAll,
    protected: true,
    props: { zone: 'secure', view: 'approved' },
  },
  {
    path: ROUTES.zones.secure.approvedByGroup,
    component: DevicesViewAll,
    protected: true,
    props: { zone: 'secure', view: 'approved' },
  },
  {
    path: ROUTES.zones.secure.unapproved,
    component: DevicesViewAll,
    protected: true,
    props: { zone: 'secure', view: 'unapproved' },
  },
  {
    path: ROUTES.zones.secure.blocked,
    component: DevicesViewAll,
    protected: true,
    props: { zone: 'secure', view: 'blocked' },
  },
  {
    path: ROUTES.zones.secure.quarantined,
    component: DevicesViewAll,
    protected: true,
    props: { zone: 'secure', view: 'quarantined' },
  },

  // Employee zone routes
  {
    path: ROUTES.zones.employee.index,
    component: EmployeeZone,
    protected: true,
  },
  {
    path: ROUTES.zones.employee.approved,
    component: DevicesViewAll,
    protected: true,
    props: { zone: 'employee', view: 'approved' },
  },
  {
    path: ROUTES.zones.employee.unapproved,
    component: DevicesViewAll,
    protected: true,
    props: { zone: 'employee', view: 'unapproved' },
  },
  {
    path: ROUTES.zones.employee.blocked,
    component: DevicesViewAll,
    protected: true,
    props: { zone: 'employee', view: 'blocked' },
  },
  {
    path: ROUTES.zones.employee.quarantined,
    component: DevicesViewAll,
    protected: true,
    props: { zone: 'employee', view: 'quarantined' },
  },

  // Guest zone routes
  {
    path: ROUTES.zones.guest.index,
    component: GuestZone,
    protected: true,
  },
  {
    path: ROUTES.zones.guest.popularDevices,
    component: PopularDevicesViewAll,
    protected: true,
    props: { zone: 'guest' },
  },

  // Shield and Network routes
  {
    path: ROUTES.shield,
    component: Shield,
    protected: true,
  },
  {
    path: ROUTES.network,
    component: Network,
    protected: true,
  },

  // Settings routes
  {
    path: ROUTES.settings.index,
    component: Settings,
    protected: true,
  },
  {
    path: ROUTES.settings.secure,
    component: Settings,
    protected: true,
    props: { active: SETTINGS_LIST.secure },
  },
  {
    path: ROUTES.settings.employee,
    component: Settings,
    protected: true,
    props: { active: SETTINGS_LIST.employee },
  },
  {
    path: ROUTES.settings.employeeLogin,
    component: Settings,
    protected: true,
    props: { active: SETTINGS_LIST.employeeLogin },
  },
  {
    path: ROUTES.settings.guest,
    component: Settings,
    protected: true,
    props: { active: SETTINGS_LIST.guest },
  },
  {
    path: ROUTES.settings.shield,
    component: Settings,
    protected: true,
    props: { active: SETTINGS_LIST.shield },
  },
  {
    path: ROUTES.settings.pods,
    component: Settings,
    protected: true,
    props: { active: SETTINGS_LIST.pods },
  },
  {
    path: ROUTES.settings.support,
    component: Settings,
    protected: true,
    props: { active: SETTINGS_LIST.support },
  },
  {
    path: ROUTES.settings.account,
    component: Settings,
    protected: true,
    props: { active: SETTINGS_LIST.account },
  },
  {
    path: ROUTES.settings.businessInfo,
    component: Settings,
    protected: true,
    props: { active: SETTINGS_LIST.businessInfo },
  },

  // Device and Employee routes
  {
    path: ROUTES.deviceByMac,
    component: Device,
    protected: true,
  },
  {
    path: ROUTES.employeeById,
    component: Employee,
    protected: true,
  },
  {
    path: ROUTES.employeeByIdAssignedDevices,
    component: DevicesViewAll,
    protected: true,
    props: { zone: 'employee', view: 'assigned' },
  },

  // App Time Insights routes
  {
    path: ROUTES.appTimeInsightsByEmployeeId,
    component: AppTimeInsights,
    protected: true,
  },
  {
    path: ROUTES.appTimeInsightsByNetworkId,
    component: AppTimeInsights,
    protected: true,
  },

  // SSID Configuration routes
  {
    path: ROUTES.ssidConfiguration.configuration,
    component: SsidConfiguration,
    protected: true,
    props: { active: SSID_CONFIGURATION_LIST.ssidConfiguration },
  },
  {
    path: ROUTES.ssidConfiguration.pods,
    component: SsidConfiguration,
    protected: true,
    props: { active: SSID_CONFIGURATION_LIST.pods },
  },
  {
    path: ROUTES.ssidConfiguration.support,
    component: SsidConfiguration,
    protected: true,
    props: { active: SSID_CONFIGURATION_LIST.support },
  },
  {
    path: ROUTES.ssidConfiguration.account,
    component: SsidConfiguration,
    protected: true,
    props: { active: SSID_CONFIGURATION_LIST.account },
  },
  {
    path: ROUTES.ssidConfiguration.secure,
    component: SsidConfiguration,
    protected: true,
    props: { active: SSID_CONFIGURATION_LIST.secure },
  },
  {
    path: ROUTES.ssidConfiguration.employee,
    component: SsidConfiguration,
    protected: true,
    props: { active: SSID_CONFIGURATION_LIST.employee },
  },
  {
    path: ROUTES.ssidConfiguration.guest,
    component: SsidConfiguration,
    protected: true,
    props: { active: SSID_CONFIGURATION_LIST.guest },
  },

  // Insurance routes
  {
    path: ROUTES.insurance.index,
    component: Insurance,
    protected: true,
  },
  {
    path: ROUTES.insurance.next,
    component: NextIframe,
    protected: true,
  },

  // 404 route
  {
    path: '*',
    component: NotFound,
    protected: false,
  },
];
