import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import StandardListItem from 'UI/Components/Lists/List standard';

import ActionSheet from 'UI/Elements/Modals/Action sheet';
import Divider from 'UI/Elements/Divider';
import Card from 'UI/Elements/Card';
import { CHECKLIST_ICON_NAMES, IconName } from 'UI/Elements/Icon';

import styles from './style.module.css';
import { Maybe, Nullable } from '../../../Consts/types';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Props } from 'UI/Elements/Toggle';
import { useTranslation } from 'react-i18next';
import useFocusFirstInteractable, {
  MenuOpenTriggerEventType,
} from 'Utils/hooks/useFocusFirstInteractable';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

import { memo } from 'react';

export type MenuItemProps = {
  className?: string;
  theme?: string;
  onClick?: React.MouseEventHandler;
  label?: Nullable<string>;
  subtitle?: Nullable<string>;
  iconName?: IconName;
  isDivider?: boolean;
  isHeader?: boolean;
  key?: number;
  preventOnClose?: boolean;
  toggle?: Nullable<Props>;
};

type MenuItemComponentProps = MenuItemProps & {
  onClose: () => void;
};

type MenuProps = {
  className?: string;
  isOpen: boolean;
  items: Maybe<MenuItemProps>[];
  parent: Nullable<Element>;
  maxHeight?: number;
  onClose: () => void;
  openTriggerEventType?: MenuOpenTriggerEventType;
};

const BELL = 'bell';
const OPEN_DELAY_MS = 50;
const MAX_RETRIES = 20;
const RETRY_INTERVAL = 200;

const findConnectedElement = (
  originalElement: Element
): Promise<Element | null> => {
  return new Promise((resolve) => {
    if (originalElement.isConnected) {
      resolve(originalElement);
      return;
    }

    // Try to find a similar button based on class
    const selector = Array.from(originalElement.classList)
      .map((className) => `.${className.replace(/([=/+])/g, '\\$1')}`)
      .join('');

    let retries = 0;

    const findElement = () => {
      const elements = Array.from(document.querySelectorAll(selector));
      const oldPos = TARGET_HELPER;

      if (oldPos)
        elements.sort((a, b) => {
          const aPos = a.getBoundingClientRect();
          const bPos = b.getBoundingClientRect();
          const aDist = Math.sqrt(
            (aPos.x - (oldPos.x || 0)) ** 2 + (aPos.y - (oldPos.y || 0)) ** 2
          );
          const bDist = Math.sqrt(
            (bPos.x - (oldPos.x || 0)) ** 2 + (bPos.y - (oldPos.y || 0)) ** 2
          );
          return aDist - bDist;
        });

      // Check each found element
      for (const element of elements) {
        if (element.isConnected) {
          resolve(element);
          return;
        }
      }

      // If no matching element found and we haven't exceeded retries
      if (retries < MAX_RETRIES) {
        retries++;
        setTimeout(findElement, RETRY_INTERVAL);
      } else {
        // If we've exhausted retries, resolve with null
        resolve(null);
      }
    };

    findElement();
  });
};

const MenuItem: FunctionComponent<MenuItemComponentProps> = ({
  theme,
  isDivider,
  label,
  subtitle,
  iconName,
  className,
  isHeader,
  toggle,
  onClick,
  onClose,
  preventOnClose,
}) => {
  const environment = getConfigurationFromDomain();
  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);
  const { t } = useTranslation();

  const classNames = cn(
    {
      [styles.danger]: theme === 'danger',
      [styles.notAvailable]: theme === 'notAvailable',
      [styles.interactive]: !!onClick,
      [styles.header]: isHeader,
    },
    className
  );

  if (isDivider) {
    return <Divider />;
  }

  const handleIconClick = (
    ev:
      | React.MouseEvent<Element, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (iconName && onClick && CHECKLIST_ICON_NAMES.includes(iconName)) {
      onClick(ev);
    }
  };

  return (
    <StandardListItem
      className={classNames}
      L2Props={{ label: label, paragraph: subtitle }}
      RProps={
        iconName
          ? {
              icon1Props: {
                name: iconName,
                onClick:
                  iconName && onClick && CHECKLIST_ICON_NAMES.includes(iconName)
                    ? handleIconClick
                    : undefined,
              },
            }
          : toggle
          ? {
              toggleProps: {
                disabled: toggle.disabled,
                checked: toggle.checked,
                onChange: toggle.onChange,
                ariaLabel: label || t('common.menuItem'),
              },
            }
          : {}
      }
      onClick={(ev) => {
        if (onClick) {
          onClick(ev);
        }

        if (!preventOnClose) {
          onClose();
        }
      }}
      navItemTheme={isBell ? 'bell' : ''}
      ariaLabel={label || t('common.menuItem')}
      isHeader={isHeader}
    />
  );
};

let TARGET_HELPER: { x?: number; y?: number } = {};

const Menu: FunctionComponent<MenuProps> = ({
  className,
  isOpen: propIsOpen,
  items,
  parent,
  maxHeight,
  onClose,
  openTriggerEventType,
}) => {
  const { focusTriggerElement } = useTrapFocus({ trapFocus: true });
  const { containerRef } = useFocusFirstInteractable<HTMLDivElement>({
    focusOnMount: false,
    focusWhen: propIsOpen,
    focusDelay: 100,
    openTriggerEventType,
  });

  useEffect(() => {
    const handleParentClick = (ev: MouseEvent) => {
      TARGET_HELPER = { x: ev.clientX, y: ev.clientY };
    };
    parent?.addEventListener('click', handleParentClick as EventListener);
    return () => {
      parent?.removeEventListener('click', handleParentClick as EventListener);
    };
  }, [parent]);

  const [isOpen, setIsOpen] = useState(false);
  const [currentParent, setCurrentParent] = useState<Nullable<Element>>(parent);

  useEffect(() => {
    let mounted = true;

    if (propIsOpen) {
      // First delay to allow for initial DOM updates
      const timeoutId = setTimeout(async () => {
        if (parent && mounted) {
          // Then search for a connected element
          const connectedElement = await findConnectedElement(parent);

          if (mounted) {
            setCurrentParent(connectedElement);
            if (connectedElement?.isConnected) {
              TARGET_HELPER = {
                x: connectedElement.getBoundingClientRect().x,
                y: connectedElement.getBoundingClientRect().y,
              };
              setIsOpen(true);
            }
          }
        }
      }, OPEN_DELAY_MS);

      return () => {
        mounted = false;
        clearTimeout(timeoutId);
      };
    } else {
      setIsOpen(false);
    }
  }, [propIsOpen, parent]);

  if (!currentParent?.isConnected || !items?.length) {
    return null;
  }

  const close = () => {
    focusTriggerElement();
    onClose();
  };

  const menuItems = () =>
    items.map((props, i) =>
      props ? <MenuItem {...props} onClose={close} key={i} /> : undefined
    );

  return (
    <ActionSheet isOpen={isOpen} parent={currentParent} onClose={close}>
      <Card noBottomPadding className={className}>
        {maxHeight ? (
          <PerfectScrollbar
            style={{
              maxHeight: `${maxHeight}px`,
            }}
          >
            <div ref={containerRef}>{menuItems()}</div>
          </PerfectScrollbar>
        ) : (
          <div ref={containerRef}>{menuItems()}</div>
        )}
      </Card>
    </ActionSheet>
  );
};

export default memo(Menu);
